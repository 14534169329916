import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

const url = process.env.NODE_ENV === 'production'
  ? ''
  : 'https://www.iidb.pl';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    itemMenu: [],
    themeSettings: {},
    currentNode: [],
    listNodes: [],
    listSlides: [],
    formData: {},
    fileData: {},
    sessionToken: '',
  },
  mutations: {
    SET_MENU(state, menu) {
      state.itemMenu = menu
    },
    SET_THEME_SETTINGS(state, settings) {
      state.themeSettings = settings
    },
    SET_NODES(state, nodes) {
      state.listNodes = nodes
    },
    SET_NODE(state, node) {
      state.currentNode = node
    },
    SET_SLIDE(state, node) {
      state.listSlides = node
    },
    SET_FORM(state, form) {
      state.formData = form
    },
    SET_FILE_FORM(state, file) {
      state.fileData = file
    },
    SET_TOKEN(state, token) {
      state.sessionToken = token
    }
  },
  actions: {
    unloadPage: ({ commit }) => (
      commit('SET_NODE', null )
    ),
    getThemeSettings({ commit }, lang) {
      axios.get(`${url}/api/v1/theme-settings/${lang}`)
        .then(response => {
          commit('SET_THEME_SETTINGS', response.data)
        });
      axios.get(`${url}/session/token?_format=json`)
        .then(response => {
          commit('SET_TOKEN', response.data)
        });
    },
    getMenu: ({ commit }, lang ) => (
      axios.get(`${url}/${lang}/api/menu_items/main`)
        .then(response => {
          commit('SET_MENU', response.data)
          return response.data;
        })
    ),
    fetchSlides: ({ commit }, lang ) => (
      axios.get(`${url}/api/v1/slide/${lang}`)
        .then(response => {
          commit('SET_SLIDE', response.data)
          return response.data;
        })
    ),
    fetchNodeByID: ({ commit }, node) => (
      //axios.get(`${url}${node.lang}/node/${node.nid}?_format=json`)
      axios.get(`${url}/api/v1/node/${node.lang}/${node.nid}`)
        .then( response => {
          commit('SET_NODE', response.data);
          return response.data;
        })
    ),
    fetchNodeBySlug: ({ commit }, node) => (
      //axios.get(`${url}${node.lang}/${node.slug}?_format=json`)
      axios.get(`${url}/api/v1/slug/${node.lang}/${node.slug}`)
        .then( response => {
          commit('SET_NODE', response.data);
          return response.data;
        })
    ),
    submitForm: ({ commit }, formData) => (
      axios.post(`${url}/webform_rest/submit?_format=json`,
        formData,{
          headers: {
            'Content-Type': 'application/json',
          }
        }).then( response => {
        commit('SET_FORM', response.data);
        return response.data;
      }).catch(response => {
        commit('SET_FORM', { e: false });
        return response.data;
      })
    ),
    submitAttachments: ({ commit }, dataFile) => (
      axios.post(`${url}/webform_rest/contact/upload/attachment?_format=json`,
        dataFile.data,{
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': `file; filename="${dataFile.fileName}"`,
          }
        }).then( response => {
        commit('SET_FILE_FORM', response.data);
        return response.data;
      }).catch(response => {
        commit('SET_FILE_FORM', { e: false });
        return response.data;
      })
    ),
  },
  modules: {
  }
})
